import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router'

import {Obj, UtilService} from '../util.service'
import {ApiService} from '../api.service'

function same(x: Obj, y: Obj) {
  var a = Object.keys(x), b = Object.keys(y)
  if (a.length != b.length) return false
  a.sort(); b.sort()
  for (var n = a.length, i = 0; i < n; ++i)
    if (a[i] != b[i]) return false
  var v, w
  for (var k of a) {
    v = x[k]; w = y[k]
    if (typeof v != typeof w) return false
    if (typeof v != 'object') {
      if (v != w) return false
      continue }
    if (!same(v, w)) return false }
  return true }

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent {
  response?: any[]
  oldParams: Obj = {dummy: null}
  total: number = 0

  constructor(private route: ActivatedRoute,
	      private util: UtilService,
	      private api: ApiService) { }

  ngOnInit() {
    //window.scrollTo(0, 0)
    ;(window as any)._route = this.route
    ;(window as any)._api = this.api
    this.api.getAll().subscribe(a => { this.total = a.length }) }

  ngDoCheck() {
    var qp = this.route.snapshot.queryParams
    if (same(this.oldParams, qp)) return
    this.oldParams = qp
    this.api.filtered(qp).subscribe(a => { this.response = a }) }

  formatUrl(s: string) { return this.util.formatUrl(s) }

  formatImg(s: string) { return this.util.formatImg(s) }

  count() {
    if (!(this.total && this.response)) return ''
    var r = [], len = this.response.length
    if (this.total != len) r.push(len, 'found in')
    r.push(this.total, 'total')
    return r.join(' ') }
}
