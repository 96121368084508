<header class="row mt-5">
  <div class="col-12 col-md-7 col-lg-8 col-xl-9">
    <div class="row">
      <a id="back" (click)="back()">Back</a>
      <div *ngIf="response['logoUrl'] && response['logoUrl'] != 'None'"
	   class="col-auto">
	<img [src]="formatImg(response['logoUrl'])" />
      </div>
      <h4 class="col">{{response['network']}}</h4>
    </div>
  </div>
</header>
<aside class="row mt-3">
  <div class="col-12 col-md-7 col-lg-8 col-xl-9">
    {{response['description']}}
  </div>
  <app-map></app-map>
</aside>
<dl class="row mt-3">
  <dt class="col-12 col-sm-3 col-lg-2">Abbreviation</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    {{response['abbreviation']}}
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Website</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    <a [href]="response['websiteUrl']"
       target="_blank">{{formatUrl(response['websiteUrl'])}}</a>
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Region</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    {{response['region']?.join(', ')}}
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Subregion</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    {{response['subregion']?.join(', ')}}
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Domain</dt>
  <dd class="col-12 col-sm-9 col-lg-10" id="domain">
    <span *ngFor="let domain of response['domain']" class="badge me-1">
      {{domain}}
    </span>
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Disciplines</dt>
  <dd class="col-12 col-sm-9 col-lg-10" id="discipline">
    <span *ngFor="let discipline of response['discipline']"
	  class="badge me-1">
      {{discipline}}
    </span>
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Organization</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    {{response['organization']?.join(', ')}}</dd>
  <dt class="col-12 col-sm-3 col-lg-2">Year Started</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    {{formatYear(response['startYear']) || ''}}
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Contact</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    <a [href]="contactHref(response)"
       target="_blank">{{contactString(response)}}</a>
  </dd>
  <dt class="col-12 col-sm-3 col-lg-2">Data Repository</dt>
  <dd class="col-12 col-sm-9 col-lg-10">
    <ul class="list-unstyled">
      <li *ngFor="let d of repositoryUrl()">
	<a [href]="d.url" target="_blank">{{formatUrl(d.url)}}</a>
      </li>
    </ul>
  </dd>
</dl>
<section class="row">
  <div class="col">
    <div class="row justify-content-center mt-3">
      <div class="col-auto">
	Observing Assets
	<button
	  id="info" class="btn btn-link" data-bs-toggle="popover"
	  type="button" data-bs-placement="top" data-bs-html="true"
	  data-bs-content='Many organizations compile structured
	    information about “observing assets”: Components of <i>in
	    situ</i> infrastructure such as monitoring sites or mobile
	    platforms, as well as activities such as projects,
	    campaigns, or initiatives.  This section conveys the level
	    of interoperability for such “asset-level” metadata –
	    whether details are tracked, if they are publicly available,
	    and if so how.  <a href="/faq#what">Learn more</a>.'>
	  <i class="bi-info-circle-fill"></i>
	</button>
      </div>
    </div>
    <dl class="row small mt-1">
      <dt class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">Asset Types</dt>
      <dd class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9" id="asset-type">
	<span *ngFor="let assetType of response['assetType']"
	      class="badge me-1">
	  {{assetType}}
	</span>
      </dd>
      <dt class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
	Asset-Level Metadata Catalog?
      </dt>
      <dd class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9">
	{{access(response['hasCatalog'])}}
      </dd>
      <dt class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
	Metadata Access?
      </dt>
      <dd class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9">
	{{access(response['dataAccess'])}}
      </dd>
      <dt class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
	Machine-Readable Access?
      </dt>
      <dd class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9">
	{{access(response['machineReadable'])}}
      </dd>
      <dt class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
	Metadata Standards
      </dt>
      <dd class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9">
	{{response['metadataStandard']?.join(', ')}}
      </dd>
      <dt class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
	Access Protocols
      </dt>
      <dd class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9">
	{{response['accessProtocol']?.join(', ')}}
      </dd>
      <dt class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
	Metadata Catalog
      </dt>
      <dd class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9">
	<ul class="list-unstyled">
	  <li *ngFor="let d of response['catalogUrl']">
	    <a [href]="d.url" target="_blank">{{formatUrl(d.url)}}</a>
	  </li>
	</ul>
      </dd>
    </dl>
  </div>
</section>
<footer class="row mt-3 small">
  <div *ngIf="response['roponId']" class="col-12">
    RoPON ID:
    <a [routerLink]="'/network/' + response['roponId']"
       target="_blank">https://polarobservingregistry.org/network/{{response['roponId']}}</a>
  </div>
  <div *ngIf="response['dateUpdated']" class="col-12">
    Last Modified: {{modDate()}}
  </div>
</footer>
