<div id="count"><p>{{count()}}</p></div>
<div *ngFor="let network of response" class="card mt-3">
  <div class="card-body">
    <div class="row">
      <div *ngIf="network.logoUrl && network.logoUrl != 'None'"
	   class="col-auto">
	<img [src]="formatImg(network.logoUrl)" />
      </div>
      <h5 class="card-title col">
	<a [routerLink]="'/network/' + network.roponId">{{network.network}}</a>
	</h5>
    </div>
    <div class="row">
      <div class="col-md-5 row">
	<div class="col-auto">Region</div>
	<div class="col">{{network.region.join(', ')}}</div>
      </div>
      <div class="col-md-7 row">
	<div class="col-auto">Subregion</div>
	<div class="col">{{network.subregion.join(', ')}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">Domain</div>
      <div class="col" id="domain">
	<span *ngFor="let domain of network.domain"
	      class="badge me-1">{{domain}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">Disciplines</div>
      <div class="col" id="discipline">
	<span *ngFor="let discipline of network.discipline"
	      class="badge me-1">{{discipline}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
	Asset Types
      </div>
      <div class="col" id="asset-type">
	<span *ngFor="let assetType of network.assetType"
	      class="badge me-1">{{assetType}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">Website</div>
      <div class="col">
	<a [href]="network.websiteUrl" class="url"
	   target="_blank">{{formatUrl(network.websiteUrl)}}</a>
      </div>
    </div>
  </div>
</div>
