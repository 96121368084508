<h1 class="mt-5">Frequently Asked Questions</h1>

<h2>What is RoPON?</h2>

<p>The Registry of Polar Observing Networks (RoPON) is a catalog of
  systems or organizations that coordinate or conduct
  observation and monitoring of climate or environmental phenomena,
  typically with data management and infrastructure such as research
  stations, platforms, or instrumentation (e.g., SIOS, GTN-P, and NASA
  ABoVE).  RoPON also displays discovery portals that are not
  observing networks <i>per se,</i> but which compile and share
  information about observing activities and infrastructure (e.g.,
  ARMAP, ISAAFFIK, and Polardex).  RoPON encompasses the polar and
  subpolar regions, including regional or global networks that extend
  into the high latitudes.</p>

<h2 id="what">What are “observing assets”?</h2>

<p>RoPON presents details about “observing assets”: Components of <i>in
  situ</i> observing infrastructure and activities, categorized into
  various “asset types”:</p>

<dl class="row">
  <dt class="col-xl-2 col-sm-3">sites</dt>
  <dd class="col-xl-10 col-sm-9">fixed stations, facilities, plots,
    moorings, observatories, stationary platforms, supersites,
    community-based observations, or locations wherever repeat
    measurements have been made.</dd>
  <dt class="col-xl-2 col-sm-3">mobile platforms</dt>
  <dd class="col-xl-10 col-sm-9">buoys, vessels, aircraft, floats,
    gliders, UAVs, AUVs, animal-borne sensors, and other mobile
    platforms.</dd>
  <dt class="col-xl-2 col-sm-3">projects</dt>
  <dd class="col-xl-10 col-sm-9">Research projects, studies, and other
    activities typically funded or coordinated by an agency or program
    within a defined timeframe.</dd>
  <dt class="col-xl-2 col-sm-3">campaigns</dt>
  <dd class="col-xl-10 col-sm-9">Scientific cruises, aircraft
    operations, fieldwork, and other planned routes or completed
    activities.</dd>
  <dt class="col-xl-2 col-sm-3">initiatives</dt>
  <dd class="col-xl-10 col-sm-9">Coordinated ongoing efforts including
    organizations, networks, observing systems, and programs.</dd>
</dl>

<p>These definitions are based on a cluster analysis of terms used by
  networks to describe what they track, as part of the activities of
  POAwg to promote the interoperability of observing-related metadata
  for polar observing.</p>

<h2>What is an asset-level metadata catalog?</h2>

<p>Many observing networks create inventories of their own research
  stations, monitoring sites, drifting buoys, funded projects,
  scientific cruises, and other types of observing assets.  In other
  words, they compile important details – e.g. site name, project title,
  latitude &amp; longitude, contact name, operating status, dates,
  etc. – as fields in a database, elements in metadata records, columns
  in a spreadsheet, or simply as a table on a webpage or PDF.
  Collectively these are broadly defined as “asset-level metadata
  catalogs”.  To facilitate information sharing, RoPON attempts to
  convey whether a network has one or more, whether they are enabled for
  viewing, if there is machine-readable access, and if so, how.</p>

<h2>Why was RoPON created?</h2>

<p>This registry was built as a step toward making observing-related
  information more Findable, Accessible, Interoperable, and Reusable
  (the FAIR Principles).  RoPON will help to: Co-locate and optimize
  limited resources; better inform local communities of activities
  nearby; guide network assessment &amp; planning; clarify best
  practices for asset-level metadata sharing; and establish a basis for
  harvesting, aggregation, &amp; federated search (for a more
  comprehensive perspective across networks).</p>

<h2>Who created RoPON?</h2>

<p>RoPON was created as a collaborative effort by the Sustaining Arctic
  Observing Networks initiative
  (<a href="https://www.arcticobserving.org" target="_blank">SAON</a>),
  the <a href="https://arcticportal.org" target="_blank">Arctic
  Portal</a>, and the SAON Polar Observing Assets Working Group
  (<a href="https://www.polarobservingassets.org"
  target="_blank">POAwg</a>).  For more information, see
  the <a routerLink="/about">About</a> page.</p>

<h2>What are future plans for RoPON?</h2>

<p>RoPON has been released as a <i>phase 1</i> version for demonstration
  purposes.  Contingent on additional funding, this registry will be
  improved with: More networks; enhancements to the UI/UX; an upgraded
  backend for more functionality; network-level user access to populate
  or update records; public, machine-readable access to the RoPON
  database via an API; ability to harvest network information
  interoperably; and otherwise to make RoPON more comprehensive and
  responsive to meet the needs of the polar observing and data
  communities.</p>

<h2>How do I add my network?  How do I change what is shown for my
  network?  How do I provide feedback?</h2>

<p>Please reach out via the <a routerLink="/contact">Contact page</a>.
  Additions, edits, and suggestions are greatly appreciated!  (Because
  RoPON is currently in <i>phase 1,</i> please also be patient as it may
  take time for changes to be enacted).</p>

<h2>Why aren’t there more networks in RoPON?</h2>

<p>In this <i>phase 1</i> version, the information for each network is
  manually curated by “screenscraping” and data wrangling from network
  websites.  More networks will be added as feasible while we seek
  additional funding.  The end goal is to have a sustainable resource
  that is distributed and interoperable.</p>

<h2>What do all of the various details mean (domains, disciplines, asset
  types, metadata standards, etc.)?  Ie., are there definitions for the
  terms shown on RoPON?</h2>

<p>Yes!  RoPON and its underlying database are structured with
  a <a href="https://www.polarobservingassets.org/resources"
  target="_blank">network-level metadata model</a> released by the SAON
  Polar Observing Assets Working Group (POAwg).  Feel free to download
  and view it to see field definitions, controlled vocabularies for
  various elements, and more.</p>
