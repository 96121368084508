import { Component } from '@angular/core';
import {Location} from '@angular/common'
import {ActivatedRoute} from '@angular/router'

import {Obj, UtilService} from '../util.service'
import {ApiService} from '../api.service'

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.css']
})
export class NetworkComponent {
  response: Obj = {}
  popover: any

  constructor(private location: Location,
	      private route: ActivatedRoute,
	      private util: UtilService,
	      private api: ApiService) { }

  modDate() {
    return (new Date(
      this.response['dateUpdated'])).toUTCString().slice(5, 16) }

  formatUrl(s: string) { return this.util.formatUrl(s) }

  formatImg(s: string) { return this.util.formatImg(s) }

  formatYear(s: string) {
    return !s ? '': Number(s.split('-')[0]) }

  contactHref(response: Obj) {
    var s = response['contact']
    return !s ? '' : s.indexOf('@') < 0 ? s : 'mailto:' + s }

  contactString(response: Obj) {
    var s = response['contact']
    return !s ? '' : s.indexOf('@') < 0 ? this.formatUrl(s) : s }

  repositoryUrl() {
    if (!('repositoryUrl' in this.response)) return []
    var a = this.response['repositoryUrl']
    if (a.length != 1) return a
    var urls = (a[0].url as string).split('%20|%20')
    if (urls.length < 2) return a
    return urls.map(s => {
      s = s.trim(); return {name: this.formatUrl(s), url: s} }) }

  access(i: number) {
    return i  // for now
    return i < 0 ? 'Under Development' : i > 0 ? 'Yes' : 'No' }

  ngOnInit() {
    window.scrollTo(0, 0)
    ;(window as any)._route = this.route.snapshot
    this.api.getOne(this.route.snapshot.params['roponId']).subscribe(
      d => { this.response = d })
    // enable popovers
    /*var a = (window as any).bootstrap.Tooltip.Default.allowList.a
    if (!a.includes('routerLink')) a.push('routerLink')*/
    this.popover = new (window as any).bootstrap.Popover(
      document.getElementById('info')) }

  ngOnDestroy() {
    this.popover.hide() }

  back() { this.location.back() }
}
