import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router'

import {Feature, Map, View} from 'ol'
import {Polygon} from 'ol/geom'
import {fromLonLat} from 'ol/proj'
import {OSM, Vector as VectorSource} from 'ol/source'
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer'

import {Obj} from '../util.service'
import {ApiService} from '../api.service'

type Coord = [number, number]
type Bound = [Coord, Coord]

function clockwise(a: number[]) {
  if (a.length != 10 || a[1] <= a[3]) return a
  var r = a.slice()
  r[3] += 360
  r[5] += 360
  return r }

function projectCoords(geoShape: Obj) {
  var g = geoShape['polygon'].split(' ').map(Number), r = []
  g = clockwise(g)
  for (var n = g.length, i = 0; i < n; i += 2)
    r.push(fromLonLat([g[i + 1], g[i]]))
  return r }

function geometries(g: Obj) {
  return (g.constructor == Array ? g : [g]).map(projectCoords) }

function globalBounds(geoms: Coord[][]): Bound {
  if (!geoms.length || !geoms[0].length) return [[0, 0], [0, 0]]
  var [x, y] = geoms[0][0], [X, Y] = geoms[0][0], z, w
  for (var g of geoms)
    for ([z, w] of g) {
      if (z < x) x = z
      else if (z > X) X = z
      if (w < y) y = w
      else if (w < Y) Y = w }
  return [[x, y], [X, Y]] }

function centre([[x, y], [X, Y]]: Bound): Coord {
  return [(x + X) / 2, (y + Y) / 2] }

function zoom([[x, y], [X, Y]]: Bound) { return  5000000 / (X - x) }

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent {
  map?: Map

  constructor(private route: ActivatedRoute,
	      private api: ApiService) { }

  ngOnInit() {
    this.api.getOne(this.route.snapshot.params['roponId']).subscribe(
      d => {
	var geoms = geometries(d.geometry)
	if (!geoms.length) return
	var b = globalBounds(geoms as Coord[][])
	this.map = new Map({
	  view: new View({center: centre(b), zoom: zoom(b)}),
	  layers: [
	    new TileLayer({source: new OSM()}),
	    new VectorLayer({
	      style: {
		'stroke-color': [171, 41, 106, 0.7],
		'stroke-width': 2,
		'fill-color': [171, 41, 106, 0.2]},
	      source: new VectorSource({features: geoms.map(
		g => new Feature(new Polygon([g])))})})],
	  target: 'map'}) }) }
}
