import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {of, tap} from 'rxjs'

import {useCache} from './util.service'

var cache: {[index: string]: string} = {}

@Injectable({
  providedIn: 'root'
})
export class CachedHttpService {

  constructor(private http: HttpClient) { }

  get(url: string) {
    return url in cache ? of(cache[url]) :
      this.http.get(url, {responseType: 'text'}).pipe(
      tap(response => { if (useCache) cache[url] = response })) }
}
