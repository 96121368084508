import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {SearchComponent} from './search/search.component'
import {AboutComponent} from './about/about.component'
import {AccessComponent} from './access/access.component'
import {ContactComponent} from './contact/contact.component'
import {PoawgComponent} from './poawg/poawg.component'
import {NetworkComponent} from './network/network.component'
import {FaqComponent} from './faq/faq.component'

const suf = ' | RoPON'

const routes: Routes = [
  {path: '', component: SearchComponent, title: 'Home' + suf},
  {path: 'about', component: AboutComponent, title: 'About' + suf},
  {path: 'contact', component: ContactComponent,
   title: 'Contact' + suf},
  {path: 'poawg', component: PoawgComponent, title: 'POAwg' + suf},
  {path: 'faq', component: FaqComponent, title: 'FAQ' + suf},
  {path: 'network/:roponId', component: NetworkComponent,
   title: 'Network Details' + suf},
  {path: 'access', component: AccessComponent, title: 'Access' + suf},
  //{path: '', redirectTo: '/search', pathMatch: 'full'}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
