import { Injectable } from '@angular/core';
import {ActivatedRoute} from '@angular/router'

export const useCache = true

export type Obj = {[index: string]: any}

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  changedQuery(route: ActivatedRoute, k: string, v: string) {
    var r: Obj = {}, p = route.snapshot.queryParams
    for (var s in p) r[s] = p[s]
    if (!v || r[k] == v) delete r[k]
    else r[k] = v
    return r }

  formatUrl(s: string | null) {
    return !s ? '' : s.replace(
      /^https?:\/\/(www\.)?|\/(index\.(html?|php))?$/gi, '') }

  formatImg(s: string) {
    return s.indexOf('http') >= 0 ? s : '/assets/' + s }
}
