<h4>Filter<i (click)="clear()" class="bi-x"></i></h4>
<ul class="list-unstyled user-select-none">
  <li>
    <span (click)="toggle('region')" [ngStyle]="stylGroup('region')">
      Region
      <i [ngClass]="caret('region')"></i>
    </span>
    <ul *ngIf="show['region']" class="list-unstyled">
      <li *ngFor="let s of items['region']">
	<a [ngStyle]="styl('region', s)"
	   [queryParams]="changedQuery('region', s)"
	   [routerLink]="[]">{{s}}</a>
      </li>
      <!-- <li>Arctic</li>
      <li>Antarctic</li>
      <li>Southern Ocean</li>
      <li>Subarctic</li>
      <li>Global</li>
      <li>Other</li> -->
    </ul>
  </li>
  <li>
    <span (click)="toggle('subregion')" [ngStyle]="stylGroup('subregion')">
      Subregion
      <i [ngClass]="caret('subregion')"></i>
    </span>
    <ul *ngIf="show['subregion']" class="list-unstyled">
      <li *ngFor="let s of items['subregion']">
	<a [ngStyle]="styl('subregion', s)"
	   [queryParams]="changedQuery('subregion', s)"
	   [routerLink]="[]">{{s}}</a>
      </li>
    </ul>
    <!-- <em *ngIf="show['subregion']">First select a region</em> -->
  </li>
  <li>
    <span (click)="toggle('domain')" [ngStyle]="stylGroup('domain')">
      Domain
      <i [ngClass]="caret('domain')"></i>
    </span>
    <ul *ngIf="show['domain']" class="list-unstyled">
      <li *ngFor="let s of items['domain']">
	<a [ngStyle]="styl('domain', s)"
	   [queryParams]="changedQuery('domain', s)"
	   [routerLink]="[]">{{s}}</a>
      </li>
    </ul>
  </li>
  <li>
    <span (click)="toggle('discipline')" [ngStyle]="stylGroup('discipline')">
      Discipline
      <i [ngClass]="caret('discipline')"></i>
    </span>
    <ul *ngIf="show['discipline']" class="list-unstyled">
      <li *ngFor="let s of items['discipline']">
	<a [ngStyle]="styl('discipline', s)"
	   [queryParams]="changedQuery('discipline', s)"
	   [routerLink]="[]">{{s}}</a>
      </li>
    </ul>
    <!-- <em *ngIf="show['discipline']">
      Follows GCOS second level category for ECVs
    </em> -->
  </li>
  <li>
    <span (click)="toggle('assetType')" [ngStyle]="stylGroup('assetType')">
      Asset Type
      <i [ngClass]="caret('assetType')"></i>
    </span>
    <ul *ngIf="show['assetType']" class="list-unstyled">
      <li *ngFor="let s of items['assetType']">
	<a [ngStyle]="styl('assetType', s)"
	   [queryParams]="changedQuery('assetType', s)"
	   [routerLink]="[]">{{s}}</a>
      </li>
      <!-- <li>Sites</li>
      <li>Mobile Platforms</li>
      <li>Projects</li>
      <li>Campaigns</li>
      <li>Initiatives</li> -->
    </ul>
  </li>
  <li>
    <span (click)="toggle('access')" [ngStyle]="stylGroup('access')">
      Asset Catalog?
      <i [ngClass]="caret('access')"></i>
    </span>
    <ul *ngIf="show['access']" class="list-unstyled">
      <li *ngFor="let s of items['access']; index as i">
	<a [ngStyle]="styl('access', 1 - i)"
	   [queryParams]="changedQuery('access', 1 - i)"
	   [routerLink]="[]">{{s}}</a>
      </li>
    </ul>
    <!-- <em *ngIf="show['access']">Need vocab</em> -->
  </li>
</ul>
