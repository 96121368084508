<h1 class="mt-5">About RoPON</h1>

<p>This web portal is the first phase of the
  <a href="https://www.polarobservingassets.org/tasks"
     target="_blank">Registry of Polar Observing Networks web portal –
     RoPON</a>.</p>

<p>The facility is developed in cooperation between the SAON secretariat
  and the Arctic Portal based on the available input and collaboration
  from the University of Colorado (Manley) and the Polar Observing
  Assets working group (POAwg).  The initial funding is provided by the
  SAON secretariat and as in-kind funding by the Arctic Portal.  Further
  funding is being sought!</p>

<p>The goal of this project is to implement a database of metadata,
  along with a machine interface (API) and a human interface (web
  frontend) for accessing these data.</p>

<p>The deliverables of the project includes at least the following:</p>
<ul>
  <li>A database implementing the document &ldquo;Metadata Model for the
    Registry of Polar Observing Systems.&rdquo;</li>
  <li>A CRUD (Create-Read-Update-Delete) API.  The API should be
    documented through README file and through OpenAPI
    Specefication.</li>
  <li>A web frontend implementing the &ldquo;RoPON&rdquo; document.
    This user interface accesses the database through the API and should
    allow navigating, searching, and filtering the database content and
    display in human-readable form.</li>
  <li>A well-documented GitHub repository containing the above.</li>
</ul>

<p>The initiative is being implemented in phases as funding is not
  secured yet for the complete project.  The first task towards the
  goals is to create a Registry of Polar Observing Networks –
  RoPON—focusing on interoperability parameters and documenting:
  Asset-related metadata standards (e.g., ISO 19115/19139, WIGOS,
  INSPIRE EF, etc.), semantic technologies (observing-related
  vocabularies and ontologies), transfer protocols (e.g., OAI-PMH, CSW,
  WFS, or custom APIs), and machine-readable endpoints that are
  currently in practice.  Use cases for the registry have been
  identified and will guide the development process moving forward.</p>

<p>The registry will have a frontend allowing users to browse, search,
  and filter for networks, with a graphical interface illustrating
  patterns of implementation.</p>

<div class="row justify-content-center mb-4">
  <div class="col-auto">
    <img src="/assets/tech-diagram.png" />
  </div>
</div>

<p>For further information on the technical implementation or the
  development plan please contact Halldór Jóhannsson —
  <a href="mailto:halldor@arcticportal.org"
     target="_blank">halldor@arcticportal.org</a></p>

<h2>About the Registry of Polar Observing Networks initiative</h2>

<h3>The Sustaining Arctic Observing Networks (SAON)</h3>

<p>SAON is a joint initiative of the Arctic Council and the
  International Arctic Science Committee that aims to strengthen
  multinational engagement in pan-Arctic observing.  The SAON process
  was established in 2011 at the Seventh Ministerial Meeting of the
  Arctic Council (AC) via the Nuuk Declaration.</p>

<p>SAON's vision is a connected, collaborative, and comprehensive
  long-term pan-Arctic Observing System that serves societal needs.</p>

<p>SAON facilitates, coordinates, and advocates for coordinated
  international pan-Arctic observations and mobilizes the support needed
  to sustain them.</p>

<p>SAON is hosted under the AMAP secretariat of the Arctic Council —
  <a href="https://www.arcticobserving.org"
     target="_blank">arcticobserving.org</a></p>

<h3>The Polar Observing Assets working group (POAwg)</h3>

<p>The Polar Observing Assets working group (POAwg) facilitates the
  discovery and interoperability of information about research &amp;
  monitoring assets in polar regions: sites, transects, observatories,
  projects, and networks or systems.  The POAwg was established in 2020
  under the SAON Committee on Observations and Networks (CON) to reduce
  effort while showcasing and integrating the efforts of multiple
  systems.</p>

<p>POAwg is led by William Manley (INSTAAR, University of Colorado).</p>

<p>POAwg participants represent a variety of networks and related
  initiatives or organizations, including: ACPS, ACTRIS, ADAC, ADC,
  ADIwg, AMAP, AOV, Arctic SDI, ARMAP, BOEM, CALM, CCADI, eLTER RI, EPB,
  ESIP, EuroARGO, GC-NET, GCW, GEO, GOOS, GTN-P, IASC/SAON ADC, ICOS,
  INTAROS, INTERACT, IODE, ITO, MODAAT, Nordicana D SILA, NPRB, NSIDC,
  OBO, PROMICE, RDA, SAON, SCADM, SIOS, SOOS, TCCON, U.S. TPC, UN
  Decade, US AON, and WMO.</p>

<p>More on the POAwg —
  <a href="https://www.polarobservingassets.org"
     target="_blank">polarobservingassets.org</a></p>

<h3>Arctic Portal</h3>

<p>ArcData—Arctic Portal.org is a provider of expert web development,
  data and consulting services focusing on the collection, processing,
  evaluation, interpretation and presentation of information and data,
  including educational and science communication products and tools
  with related consulting and outreach services for business,
  policy makers, educators and alike.</p>

<p>ArcData operates the Arctic Portal.org and its IT development,
  outreach and data services, a comprehensive gateway to Arctic
  information and data on the internet, increasing information sharing
  and co-operation among Arctic stakeholders and granting exposure to
  arctic related information and data.  It is a network of information
  and data sharing and serves as a developer and host to various
  websites and portals in a circumpolar context, supporting co-operation
  and outreach and data sharing in science, education, and policy
  making.</p>

<p>More on the Arctic Portal –
  <a href="https://www.arcticportal.org"
     target="_blank">arcticportal.org</a></p>

<h2>See also</h2>
<ul>
  <li>A
    &ldquo;<a href="https://drive.google.com/file/d/108HR5sifkbBrubnJE9yT6hO_OkC35Ksu/view?usp=sharing"
	      target="_blank">Short Statement</a>&rdquo; from the Arctic
    Observing Summit 2022</li>
  <li>A
    <a href="https://drive.google.com/file/d/18-1qmypbln09i7LtY3zqJm6JDyxN17V0/view?usp=sharing"
       target="_blank">short presentation</a> for the Polar Data Forum
    IV</li>
</ul>
