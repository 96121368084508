import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router'

import {Obj, UtilService} from '../util.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {
  constructor(private route: ActivatedRoute,
	      private router: Router,
	      private util: UtilService) { }

  searchString() {
    return this.route.snapshot.queryParams['search'] || '' }

  clear(elt: Obj) {
    elt['value'] = ''
    this.router.navigate([], {
      queryParams: this.util.changedQuery(this.route, 'search', '')}) }

  doSearch(s: string) {
    this.router.navigate([], {
      queryParams: this.util.changedQuery(this.route, 'search', s)}) }
}
