<h1 class="mt-5">Polar Observing Assets Working Group</h1>

<h2>Purpose of the Registry of Polar Observing Networks – RoPON</h2>

<p>The polar research community maintains numerous observing systems
  that collect observations of essential polar variables.  The Polar
  Observing Asset working group (POAwg) provides technical guidance for
  sharing information about observing activities.  The POAwg promotes
  interoperability toward a comprehensive perspective of observing
  activities and clarifies solutions for the deployment of
  interoperable, observing-related metadata catalogs.  This information
  can then help strengthen observing systems, create knowledge maps that
  link observations to societal needs, and measure capacity for
  assessing environmental conditions.</p>

<p>The Polar Observing Assets working group (POAwg) facilitates the
  discovery and interoperability of information about research &amp;
  monitoring assets in polar regions: sites, transects, observatories,
  projects, and networks or systems.</p>

<p>The POAwg was established in 2020 under the SAON Committee on
  Observations and Networks (CON) to reduce effort while showcasing and
  integrating the efforts of multiple systems.</p>

<h4>Goals of the POAwg:</h4>

<ul>
  <li>Make asset information more Findable, Accessible, Interoperable,
    and Reusable</li>
  <li>Promote best practices for interoperability &ldquo;beyond the
    dataset level&rdquo;</li>
  <li>Help span a spectrum across science planning, data management
    &amp; disciplinary or interdisciplinary science</li>
  <li>Create a registry of Polar observing networks, documenting
    asset-related metadata standards, semantic technologies, and
    transfer protocols in use</li>
  <li>Build crosswalks and facilitate existing tools for translation
    across standards</li>
  <li>Create recommendations for adoption and implementation of
    established standards and solutions</li>
</ul>

<p><a href="https://www.polarobservingassets.org" target="_blank">More
    information can be found on the POAwg website</a></p>
