import { Component } from '@angular/core';

@Component({
  selector: 'app-poawg',
  templateUrl: './poawg.component.html',
  styleUrls: ['./poawg.component.css']
})
export class PoawgComponent {

}
