import { Injectable } from '@angular/core';
import {forkJoin, map, mergeMap, of, tap, zip} from 'rxjs'

import {Obj, useCache} from './util.service'
import {CachedHttpService} from './cached-http.service'
import {env} from './env.service'

//const prefix = 'http://poawg.arcticportal.org:28000/v1/'
/*const port = env.PLATFORM_TIER == 'prod' ?
  env.FRONTEND_PORT_EXTERNAL : env.BACKEND_PORT*/
const port = env.FRONTEND_PORT_EXTERNAL
const prefix = `${env.PROTOCOL}://${env.BASE_URL}:${port}/v1/`

const mapping: Obj = {
  access_protocols: 'accessProtocol',
  asset_types: 'assetType',
  data_repository_urls: 'repositoryUrl',
  disciplines: 'discipline',
  domains: 'domain',
  has_catalog: 'hasCatalog',
  id: 'roponId',
  logo_url: 'logoUrl',
  machine_readable: 'machineReadable',
  metadata_access: 'dataAccess',
  metadata_catalog_urls: 'catalogUrl',
  metadata_standards: 'metadataStandard',
  name: 'network',
  organizations: 'organization',
  regions: 'region',
  subregions: 'subregion',
  website_url: 'websiteUrl',
  year_started: 'startYear'}

const metaOrder: Obj = {
  region: [
    'Arctic', 'Subarctic', 'Antarctic', 'Southern Ocean', 'Global',
    'Other'],
  subregion: [
    // Arctic: Land
    'Alaska', 'Canadian Arctic', 'Greenland', 'Iceland', 'Svalbard',
    'Scandinavia', 'Russian Arctic',
    // Arctic: Ocean
    'Sea of Okhotsk', 'Gulf of Alaska', 'Bering Sea', 'Chukchi Sea',
    'Beaufort Sea', 'Canadian Arctic Archipelago', 'Hudson Bay',
    'Labrador Sea', 'Baffin Bay', 'Greenland Sea', 'Norwegian Sea',
    'Barents Sea', 'Kara Sea', 'Laptev Sea', 'East Siberian Sea',
    'Central Arctic Ocean',
    // Subarctic
    'Canadian Subarctic', 'European Subarctic', 'Russian Subarctic',
    // Antarctic: Land
    'Antarctic Peninsula', 'East Antarctica',
    'Transantarctic Mountains', 'West Antarctica', 'Dronning Maud Land',
    // Southern Oceans
    'Ross Sea', 'Amundsen Sea', 'Bellinghausen Sea', 'Scotia Sea',
    'Weddell Sea', 'East Antarctic Seas',
    // If a network spans too many subregions to delineate separately
    'multiple'],
  domain: ['Atmosphere', 'Land', 'Ocean'],
  discipline: [
    'Biology', 'Cryosphere', 'Data Management',
    'Education and Outreach', 'Geological Sciences',
    'Instrument Development', 'Meteorology and Climate', 'Oceanography',
    'Social and Human Sciences', 'Space Physics'],
  assetType: [
    'sites', 'mobile platforms', 'projects', 'campaigns',
    'initiatives']}

function adapt(d: Obj) {
  var r: Obj = {}
  for (var k in d) r[k in mapping ? mapping[k] : k] = d[k]
  return r }

function catalogMatch(d: Obj, n: number) {
  switch ((d['hasCatalog'] as string).trim().toLowerCase()) {
    case 'yes': return n == 1
    case 'no':  return n == 0 }
  return n == -1 }

function sortMeta(typ: string, d: Obj) {
  var x: Obj = {}, a = metaOrder[typ], r = []
  for (var k in d) x[k] = null
  for (k of a) {
    if (!(k in x)) continue
    r.push(k)
    delete x[k] }
  for (k of Object.keys(x).sort()) r.push(k)
  return r }


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  _db: Obj = {}
  _lst: Obj[] = []

  constructor(private http: CachedHttpService) { }

  getAll() {
    return this._lst.length ? of(this._lst) :
      this.http.get(prefix + 'network?order_by=abbreviation').pipe(
	map(s => JSON.parse(s).map(adapt)),
	tap(a => { if (useCache) this._lst = a })) }

  getOne(roponId: string) {
    return roponId in this._db ? of(this._db[roponId]) :
      this.http.get(prefix + 'network/' + roponId).pipe(
	map(s => adapt(JSON.parse(s))),
	tap(d => { if (useCache) this._db[roponId] = d })) }

  zipAll() {
    return 'zipAll' in this._db ? of(
      this._db['zipAll']) : this.getAll().pipe(
	mergeMap(all => forkJoin(all.map((d: Obj) => this.getOne(
	  d['roponId']))).pipe(map(ones => ({all: all, ones: ones})))),
	tap(d => { if (useCache) this._db['zipAll'] = d })) }

  filterItems(s: string) {
    var r: Obj = {}, k, v
    return this.getAll().pipe(map(a => {
      for (v of a) for (k of v[s]) r[k] = null
      //return Object.keys(r).sort()
      return sortMeta(s, r) })) }

  regions() { return this.filterItems('region') }

  subregions() { return this.filterItems('subregion') }

  domains() { return this.filterItems('domain') }

  disciplines() { return this.filterItems('discipline') }

  assetTypes() { return this.filterItems('assetType') }

  accesses() { return of(['Yes', 'No', 'Under Development']) }

  searchSuccess(d: Obj, s: string) {
    var v
    s = s.toLowerCase()
    for (var k in d) {
      v = d[k]
      if (typeof v == 'number') v = v.toString()
      if (typeof v == 'string') {
	v = v.toLowerCase()
	if (v.search(s) >= 0) return true }
      else if (typeof v == 'object')
	if (this.searchSuccess(v, s)) return true }
    return false }

  match(d: Obj, k: string, v: any) {
    switch (k) {
      case 'access': return catalogMatch(d, v)
      case 'search': return this.searchSuccess(d, v) }
    return typeof d[k] == 'object' ? d[k].includes(v) : d[k] == v }

  filtered(p: Obj) {
    var r: Obj[] = [], flag, k, d: Obj
    return this.zipAll().pipe(map(x => {
      for (d of x.all) {
	flag = true
	for (k in p) {
	  if (this.match(d, k, p[k])) continue
	  if (k == 'search' && this.searchSuccess(x.ones.find(
	    (one: Obj) => one['roponId'] == d['roponId']), p[k]))
	      continue
	  flag = false
	  break }
	if (flag) r.push(d) }
      return r })) }
}
