import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router'

import {Obj, UtilService} from '../util.service'
//import {DummyApiService} from '../dummy-api.service'
import {ApiService} from '../api.service'

type Bools = {[index: string]: boolean}

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css']
})
export class SearchFilterComponent {
  show: Bools = {
    region: false,
    subregion: false,
    domain: false,
    discipline: false,
    assetType: false,
    access: false}
  items: {[index: string]: string[]} = {
    region: [],
    subregion: [],
    domain: [],
    discipline: [],
    assetType: [],
    access: []}

  constructor(private route: ActivatedRoute,
	      private router: Router,
	      private util: UtilService,
	      public api: ApiService) { }

  ngOnInit() {
    for (var k of ['region', 'subregion', 'domain',
		   'discipline', 'assetType', 'access'])
      this.show[k] = this.childSelected(k)
    this.api.regions().subscribe(
      a => { this.items['region'] = a })
    this.api.subregions().subscribe(
      a => { this.items['subregion'] = a })
    this.api.domains().subscribe(
      a => { this.items['domain'] = a })
    this.api.disciplines().subscribe(
      a => { this.items['discipline'] = a })
    this.api.assetTypes().subscribe(
      a => { this.items['assetType'] = a })
    this.api.accesses().subscribe(
      a => { this.items['access'] = a }) }

  caret(name: string) {
    return 'bi-caret-' +
      (this.show[name] ? 'down' : 'right') +
      '-fill' }

  toggle(name: string) { this.show[name] = !this.show[name] }

  childSelected(k: string) {
    return this.route.snapshot.queryParams.hasOwnProperty(k) }

  selected(k: string, v: string) {
    return this.route.snapshot.queryParams[k] == v }

  changedQuery(k: string, v: any) {
    return this.util.changedQuery(this.route, k, String(v)) }

  styl(k: string, v: any) {
    return !this.selected(k, String(v)) ? {} : {
      'font-weight': 'var(--ropon-bold)'} }

  stylGroup(k: string) {
    return !this.childSelected(k) ? {} : {
      'font-weight': 'var(--ropon-bold)'} }

  clear() {
    var r: Obj = {}, p = this.route.snapshot.queryParams
    for (var s in p) r[s] = p[s]
    for (s of ['region', 'subregion', 'domain',
	       'discipline', 'assetType', 'access'])
      delete r[s]
    this.router.navigate([], {queryParams: r}) }
}
