<div class="row mt-3">
  <div class="col-12">
    <p class="lead">Systems and related organizations that coordinate or
      track observing activities &amp; infrastructure in the polar
      regions.</p>
    <div class="alert alert-warning">
      <i class="bi-info-circle-fill me-1"></i>
      <!-- This registry has been released as a phase 1 version for
      demonstration purposes.  Plans are underway as funding allows for
      improvements in scope, functionality, and interoperability.  For
      further information, visit the <a routerLink="/about">About</a>
      section.  Feedback is appreciated.-->
      This registry has been released as a phase 1 version for
      demonstration purposes.  Plans are underway for improvements in
      scope, functionality, and interoperability.  Feedback is
      appreciated.
    </div>
  </div>
  <app-search-filter class="col-12 col-sm-4 col-md-3">
  </app-search-filter>
  <div class="col">
    <div class="row">
      <div class="col">
	<div class="input-group input-group-sm">
	  <input #search type="text" class="form-control"
		 [value]="searchString()"
		 (keyup.enter)="doSearch(search.value)"
		 placeholder="Search &hellip;" aria-label="Search" />
	  <i (click)="clear(search)" class="bi-x"></i>
	  <button (click)="doSearch(search.value)"
		  class="btn btn-primary" type="button">
	    <i class="bi-search"></i>
	  </button>
	</div>
      </div>
      <!-- <div class="col-auto">
	<select class="form-select form-select-sm" aria-label="Sort by">
	  <option selected>Sort by</option>
	  <option style="color: var(--bs-red)">Need criteria</option>
	</select>
      </div> -->
    </div>
    <!-- <header class="row mt-3">
      <div class="col">Showing 1-20 of 132</div>
      <div class="col-auto" style="color: var(--bs-gray)">
	<i class="bi-arrow-left"></i>
	Previous
      </div>
      <div class="col-auto">
	Next
	<i class="bi-arrow-right"></i>
      </div>
    </header> -->
    <app-search-result></app-search-result>
  </div>
</div>
<footer class="row mt-4 small">
  <!-- RoPON was created as a collaborative effort by the Polar Observing
  Assets Working Group and is maintained by an international
  organization, Sustaining Arctic Observing Networks (SAON). -->
  RoPON was created as a collaborative effort by the Sustaining Arctic
  Observing Networks initiative (SAON), the Arctic Portal, and the SAON
  Polar Observing Assets Working Group (POAwg), and is funded by the
  NOAA GOMO Arctic Research Program.
  <div>
    <img src="/assets/GOMO_Lockup_Outlines-2.svg" />
  </div>
</footer>
